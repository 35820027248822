import * as Sentry from "@sentry/vue";

export default {
    response: [
        /**
         * Checks if response status is unauthorized and redirect if applies
         */
        (res) => {
            const version = process.env.VUE_APP_VERSION || "local";
            if (res && res.status === 500 && version !== "local") {
                Sentry.addBreadcrumb({
                    category: "Request body",
                    message: JSON.stringify(res.config.data),
                    level: Sentry.Severity.Info,
                });

                Sentry.captureException(new Error("500: " + res.config.url), {extra: res});
            }

            return res;
        },
    ],
};
